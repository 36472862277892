import React, { useState, useEffect, useRef } from "react";
import { Marker, Popup, useMapEvents, useMap } from "react-leaflet";
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";
import icon from "./../assets/marker-1-transformed.png";
import Logo from "./../assets/logo.png";
import MapIcon from "@mui/icons-material/Map";
import Spinner from "./../components/spinner";
import { BackendErrorToast } from "./../components/toasts";
import "../assets/css/app.css";
import {
  CButton, CCard, CCardBody, CCardText, CCardTitle,
  CCloseButton,
  CCol,
  CContainer,
  CForm,
  CFormFloating,
  CInputGroup,
  CNavbar,
  CNavbarBrand,
  CRow,
  CSpinner,
  CToaster,
} from "@coreui/bootstrap-react";

// import { Field, Formik } from "formik";
import DemandeModal from "./DemandeModal";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import Chip from "@mui/material/Chip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AssignmentSharpIcon from "@mui/icons-material/AssignmentSharp";
import IconButton from "@mui/material/IconButton";
import { Formik, Form, ErrorMessage } from "formik";

import Maps from "./Maps";
import { toast, ToastContainer } from "react-toastify";

const Home = () => {
  const [planMapChecked, setPlanMapChecked] = useState(true);
  const [satelliteMapChecked, setSatelliteMapChecked] = useState(false);
  const [status, setStatus] = useState("");
  // const [infosData, setInfosData] = useState(0);
  const [fromBack, setFromBack] = useState(false);
  const [position, setPosition] = useState([]);
  const [loadPosition, setLoadPosition] = useState(false);
  // const [latLonOnForm, setLatLonOnForm] = useState(false);
  // const [latOnForm, setLatOnForm] = useState(false);
  // const [lonOnForm, setLonOnForm] = useState(false);
  // const [latOnForm, setLatOnForm] = useState(false);
  const [showInitialPosition, setShowInitialPosition] = useState(true);

  // const [latitudeF, setLatitudeF] = useState();
  // const [longitudeF, setLongitudeF] = useState();

  const [isElligible, setIsElligible] = useState(true);
  const fillSuccessOptionsOptions = { fillColor: "green", color: "green" };
  const fillLoadingOptionsOptions = { fillColor: "gray", color: "gray" };
  const fillFailureOptionsOptions = { fillColor: "red", color: "red" };
  const [lat, setLat] = useState(6.129935);
  const [lon, setLon] = useState(1.216016);
  const [latForm, setLatForm] = useState(6.129935);
  const [lonForm, setLonForm] = useState(1.216016);
  // const [visible, setVisible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [fromForm, setFromForm] = useState(0);
  const [fromFormAcessibility, setFromFormAcessibility] = useState(0);
  const [recenterUser, setRecenterUser] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [demandtoast, addDemandToast] = useState(0);
  const [resultIsLoading, setResultIsLoading] = useState(false);
  const toaster = useRef();
  const url = `${process.env.REACT_APP_API_BASE_URL}/check`;
  const MAX_DISTANCE = 200;

  const [formValues, setFormValues] = useState({ latitudeForm: "", longitudeForm: "" });

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  let DefaultIcon = window.L.icon({
    iconUrl: icon,
    iconSize: [25, 38],
    iconAnchor: [12, 36],
    popupAnchor: [2, -41],
  });

  function LocationMarker({ fromForm, fromFormAcessibility }) {
    const [position, setPosition] = useState(null);
    const map = useMap();

    if (fromForm) {
      if (fromForm === 1) {
        setShowInitialPosition(false);
        setFromForm(0);
        map.flyTo({ lat: latForm, lng: lonForm }, map.getZoom());
        setResultIsLoading(true);
        fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            latitude: lat,
            longitude: lon,
            distance: MAX_DISTANCE,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status.code === 404) {
              setResultIsLoading(false);
              setIsElligible(false);
              setStatus("Inéligible");
            }
            if (data.status.code === 500) {
              setIsElligible(false);
              setResultIsLoading(false);
              console.log("error response from backend:::");
              addDemandToast(BackendErrorToast);
            }
            if (data.status.code === 200) {
              if (data.data !== null) {
                setStatus("Eligible");
                setResultIsLoading(false);
                setFromBack(true);
                setIsElligible(true);
              } else {
                setIsElligible(false);
                setStatus("Inéligible");
              }
              setResultIsLoading(false);
            }
          })
          .catch((error) => {
            setResultIsLoading(false);
            console.log("error to contact backend:::");
            addDemandToast(BackendErrorToast);
          });
      }
      if (fromForm === 2) {
        setFromBack(false);
        navigator.geolocation.getCurrentPosition(function (position) {
          setLat(position.coords.latitude);
          setLon(position.coords.longitude);
          setFormValues({
            latitudeForm: position.coords.latitude.toFixed(7),
            longitudeForm: position.coords.longitude.toFixed(7),
          });
          map.flyTo(
            { lat: position.coords.latitude, lng: position.coords.longitude },
            map.getZoom()
          );
        });
        setRecenterUser(false);
        setShowInitialPosition(false);
        setFromForm(0);
      }
    }

    if (fromFormAcessibility) {
      if (fromFormAcessibility === 3) {
        map.zoomIn();
        setFromFormAcessibility(0);
      }

      if (fromFormAcessibility === 4) {
        map.zoomOut();
        setFromFormAcessibility(0);
      }

      if (fromFormAcessibility === 5) {
        setFromBack(false);
        navigator.geolocation.getCurrentPosition(function (position) {
          // setLat(position.coords.latitude);
          // setLon(position.coords.longitude);
          map.flyTo(
            { lat: position.coords.latitude, lng: position.coords.longitude },
            map.getZoom()
          );
        });
        // setLatLonOnForm(true)
        setFromFormAcessibility(0);
      }
    }

    useMapEvents({
      click(e) {
        setLat(e.latlng.lat);
        setLon(e.latlng.lng);
        setPosition(e.latlng);
        setShowInitialPosition(false);
        setFormValues({
          latitudeForm: e.latlng.lat.toFixed(7),
          longitudeForm: e.latlng.lng.toFixed(7),
        });
      },
    });

    return position === null ? null : (
      <Marker position={position} icon={DefaultIcon}>
        <Popup>You are here</Popup>
      </Marker>
    );
  }

  const toggleSatelliteMap = () => {
    planMapChecked
      ? setSatelliteMapChecked(!satelliteMapChecked)
      : setSatelliteMapChecked(satelliteMapChecked);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setPosition([6.129935, 1.216016]);
    setLoadPosition(true);

    return () => {
      setPosition([6.129935, 1.216016]);
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const changeResult = () => {
    setResultIsLoading(true);
    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        latitude: lat,
        longitude: lon,
        distance: MAX_DISTANCE,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status.code === 404) {
          setResultIsLoading(false);
          setIsElligible(false);
          setFromBack(true);
          setStatus("Inéligible");
        }
        if (data.status.code === 500) {
          setIsElligible(false);
          setResultIsLoading(false);
          console.log("error response from backend:::");
          addDemandToast(BackendErrorToast);
        }
        if (data.status.code === 200) {
          // Result Come from Backend
          setFromBack(true);
          if (data.data !== null) {
            setStatus("Eligible");
            setResultIsLoading(false);
            setIsElligible(true);
          } else {
            setIsElligible(false);
            setStatus("Inéligible");
          }
          setResultIsLoading(false);
        }
      })
      .catch((error) => {
        setResultIsLoading(false);
        console.log("error to contact backend:::");
        addDemandToast(BackendErrorToast);
      });
  };

  useEffect(() => {
    if (lat !== 6.129935 && lon !== 1.216016) {
      changeResult();
    }
  }, [lat, lon]);

  const validateFields = (values) => {
    const errors = {};

    // if (!values.latitude) {
    //   errors.latitude = "Ce champ est obligatoire";
    // } else {
    //   // Remplacer la virgule par un point
    //   const latitudeValue = values.latitude.trim().replace(/,/g, ".");
    //   const latitudeValueNum = Number(latitudeValue);
    //   if (isNaN(latitudeValueNum)) {
    //     errors.latitude = "La valeur de la latitude saisie n'est pas valide";
    //   }
    // }
    // if (values.latitude) {
    // Remplacer la virgule par un point
    const latitudeValue = values.latitudeForm.trim().replace(/,/g, ".");
    const latitudeValueNum = Number(latitudeValue);

    if (isNaN(latitudeValueNum)) {
      errors.latitudeForm = "La valeur de la latitude saisie n'est pas valide";
    }

    const longitudeValue = values.longitudeForm.trim().replace(/,/g, ".");
    const longitudeValueNum = Number(longitudeValue);

    if (isNaN(longitudeValueNum)) {
      errors.longitudeForm = "La valeur de la longitude saisie n'est pas valide";
    }

    return errors;
  };

  const showToastMessage = () => {
    toast.success("Demande envoyée avec succès !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageError = () => {
    toast.error("Demande non envoyé. Veuillez réessayer ultérieurement !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageErrorBackend = () => {
    toast.error(
      "Veuillez verifier votre connexion. Rafraichir la page et réessayer.",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  };

  return loadPosition === true ? (
    <div className="mainBody" style={{backgroundColor: '#00377D'}}>
      <CContainer fluid id="mainContainer" style={{backgroundColor: '#00377D'}}>
        <CToaster ref={toaster} push={demandtoast} placement="top-end" />
        <DemandeModal
          lat={lat}
          lon={lon}
          status={status}
          onSuccess={showToastMessage}
          onError={showToastMessageError}
          onErrorBack={showToastMessageErrorBackend}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
        <ToastContainer />
        <CNavbar expand="xxl" style={{backgroundColor: '#00377D'}}>
          <CContainer fluid style={{backgroundColor: '#00377D'}}>
            <CNavbarBrand href="#" >
              <img
                src={Logo}
                height="50"
                width="50"
                alt="current-weather-status"
                className="d-inline-block align-top"
              />{" "}
              <span style={{color: '#ffffff' }}>Vérifiez votre éligibilité</span>
            </CNavbarBrand>
          </CContainer>
        </CNavbar>
        <CRow>
          <CCol xs={12} className="p-3 bg-yellow" id="block1">
            <div className="map">
              <Maps
                position={position}
                planMapChecked={planMapChecked}
                satelliteMapChecked={satelliteMapChecked}
                lat={lat}
                lon={lon}
                isElligible={isElligible}
                setIsElligible={setIsElligible}
                resultIsLoading={resultIsLoading}
                setResultIsLoading={setResultIsLoading}
                fillLoadingOptionsOptions={fillLoadingOptionsOptions}
                fillSuccessOptionsOptions={fillSuccessOptionsOptions}
                fillFailureOptionsOptions={fillFailureOptionsOptions}
                DefaultIcon={DefaultIcon}
                LocationMarker={LocationMarker}
                fromForm={fromForm}
                fromFormAcessibility={fromFormAcessibility}
              />

              <div
                className={`banner ${
                  showForm ? "componentB" : "componentBClose"
                }`}
              >
                {showForm === true ? (
                  <div className="mainForm">
                    <CRow>
                      <CCol xs={8} className="p-1">
                        <span style={{ fontSize: 35, fontWeight: "bold", color:"#00377D"}}>
                          Bienvenue
                        </span>
                      </CCol>
                      <CCol
                        xs={12}
                        className="p-1"
                        style={{ marginBottom: 15 }}
                      >
                        <span style={{ color: "#5F99D2" }}>
                          Utilisez ce formulaire pour vérifier votre éligibilité
                        </span>
                      </CCol>
                      <CCol
                        xs={2}
                        className="p-1"
                        style={{ position: "absolute", right: "-8%" }}
                      >
                        <CCloseButton onClick={() => setShowForm(!showForm)} />
                      </CCol>
                    </CRow>

                    <Formik
                      initialValues={formValues}
                      validate={(values) => validateFields(values)}
                      onSubmit={(values, { setSubmitting }) => {
                        setFormValues(values);
                        console.log("Form values:", values);

                        setSubmitting(false);
                        const latitForm = Number(
                          values.latitudeForm.trim().replace(/,/g, ".")
                        );
                        const longitForm = Number(
                          values.longitudeForm.trim().replace(/,/g, ".")
                        );
                        setLatForm(latitForm);
                        setLonForm(longitForm);
                        setLat(latitForm);
                        setLon(longitForm);
                        setFromForm(1);
                      }}
                      enableReinitialize
                    >
                      {({ isSubmitting, handleChange, values }) => (
                        <Form autoComplete="off">
                          <div
                            className="flex-nowrap"
                            style={{ marginBottom: 5 }}
                          >
                            <div className="mb-2">
                              <TextField
                                label="LATITUDE"
                                variant="filled"
                                fullWidth
                                type="text"
                                id="latitudeForm"
                                name="latitudeForm"
                                required
                                autoFocus={true}
                                value={values.latitudeForm}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFormValues((prevValues) => ({
                                    ...prevValues,
                                    latitudeForm: e.target.value,
                                  }));
                                }}
                                sx={{
                                  input: { color: "#00377D" },
                                  label: { color: "#5F99D2" }
                                }}
                              />
                              <ErrorMessage
                                name="latitudeForm"
                                style={{ color: "red" }}
                                component="div"
                              />
                            </div>
                          </div>

                          <div
                            className="flex-nowrap"
                            style={{ marginBottom: 5 }}
                          >
                            <div className="mb-2">
                              <TextField
                                label="LONGITUDE"
                                variant="filled"
                                fullWidth
                                type="text"
                                id="longitudeForm"
                                name="longitudeForm"
                                required
                                value={values.longitudeForm}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFormValues((prevValues) => ({
                                    ...prevValues,
                                    longitudeForm: e.target.value,
                                  }));
                                }}
                                sx={{
                                  input: { color: "#00377D" },
                                  label: { color: "#5F99D2" }
                                }}
                              />
                              <ErrorMessage
                                name="longitudeForm"
                                style={{ color: "red" }}
                                component="div"
                              />
                            </div>
                          </div>

                          <Stack direction="row" spacing={2}>
                            <LoadingButton
                                onClick={() => {
                                  setFromForm(2);
                                }}
                                sx={{
                                  backgroundColor: "white",
                                  border: "1px solid #5F99D2",
                                  "&:hover": {
                                    backgroundColor: "#ffffff",
                                    borderColor: "#5F99D2",
                                  },
                                }}
                                loadingPosition="start"
                                startIcon={<MyLocationIcon style={{ color: "#5F99D2" }} />}
                                variant="contained"
                            >
                              <span style={{ color: "#5F99D2" }}>Utiliser ma position actuelle</span>
                            </LoadingButton>


                            <LoadingButton
                              type="submit"
                              loading={
                                (lon !== 1.216016 &&
                                  lat !== 6.129935 &&
                                  resultIsLoading) ||
                                isSubmitting
                              }
                              sx={{
                                backgroundColor: "#00377D",
                              }}
                              loadingPosition="end"
                              startIcon={<CheckCircleIcon style={{color: "white"}} />}
                              variant="contained"
                            >
                              <span style={{color: "white"}}>Vérifier</span>
                            </LoadingButton>
                          </Stack>
                        </Form>
                      )}
                    </Formik>
                    <CRow>
                      <CCol xs={12} className="p-1">
                        <hr style={{ marginTop: 8 }} />
                      </CCol>
                      {fromBack === true &&
                      lon !== 1.216016 &&
                      lat !== 6.129935 ? (
                        <CCol xs={12} className="p-1">
                          <span style={{ fontSize: 18, color: "#00377D", fontWeight: "600" }}>RÉSULTAT</span>
                        </CCol>
                      ) : (
                        ""
                      )}
                    </CRow>

                    {fromBack === true &&
                    lon !== 1.216016 &&
                    lat !== 6.129935 ? (
                      resultIsLoading === true ? (
                        <CSpinner
                          component="span"
                          size="lg"
                          aria-hidden="true"
                        />
                      ) : (
                        <div>
                          {isElligible === true ? (
                            <div>
                              <div
                                style={{
                                  color: "whitesmoke",
                                  marginTop: "0px",
                                  backgroundColor: "#5F99D2",
                                  border: "none",
                                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)",
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "10px",
                                  borderRadius: "0.375rem",
                                }}
                              >
                                <span
                                  style={{ fontSize: 25, fontWeight: "bold" }}
                                >
                                  {" "}
                                  <CheckCircleIcon fontSize="large" style={{ color: "white" }} />
                                  <span style={{ color: "white" }} >  Vous êtes éligible</span>
                                </span>
                              </div>
                              <div
                                style={{
                                  marginTop: "24px",
                                  backgroundColor: "#E8E8E8",
                                  border: "none",
                                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)",
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "10px",
                                  borderRadius: "0.375rem",
                                  width: '70%'
                                }}
                              >
                                  <CRow>
                                    <CCol sm={2}>
                                      <div
                                          style={{
                                            marginTop: '50%',
                                            marginLeft: '2px',
                                            marginRight: '5px'
                                          }}
                                      >
                                        <GpsFixedOutlinedIcon
                                            sx={{
                                              fontSize: "50px",
                                              backgroundColor: "",
                                              color: "#00377D",
                                              width: 50,
                                              height: 50,
                                              borderRadius: "50%",
                                            }}
                                        />
                                      </div>
                                    </CCol>
                                    <CCol sm={5} style={{ marginTop: '5px' }}>
                                      <CCard>
                                        <CCardBody>
                                          <CCardTitle><span style={{color: '#5F99D2'}}>Latitude</span></CCardTitle>
                                          <CCardText><span style={{color: '#00377D'}}>{lat.toFixed(7)}</span></CCardText>
                                        </CCardBody>
                                      </CCard>
                                    </CCol>
                                    <CCol sm={5} className="p-1">
                                      <CCard>
                                        <CCardBody>
                                          <CCardTitle><span style={{color: '#5F99D2', }}>Longitude</span>{ " " }</CCardTitle>
                                          <CCardText><span style={{color: '#00377D'}}>{lon.toFixed(7)}</span></CCardText>
                                        </CCardBody>
                                      </CCard>
                                    </CCol>
                                  </CRow>
                              </div>
                              <br />
                              <CForm
                                className="d-flex"
                                style={{ textAlign: "center" }}
                              >
                                <Button
                                  style={{ backgroundColor: "#00377D" }}
                                  onClick={() => setModalVisible(true)}
                                  variant="contained"
                                  startIcon={<AddCircleIcon style={{ color: "white", width:25, height: 25 }}/>}
                                >
                                  <span style={{ color: "white", fontSize: 20  }}>Faire une demande</span>
                                </Button>
                              </CForm>
                            </div>
                          ) : (
                              <div>
                                <div
                                    style={{
                                      color: "whitesmoke",
                                      marginTop: "0px",
                                      backgroundColor: "#FFA602",
                                      border: "none",
                                      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)",
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "10px",
                                      borderRadius: "0.375rem",
                                    }}
                                >
                                <span
                                    style={{ fontSize: 25, fontWeight: "bold" }}
                                >
                                  {" "}
                                  <CancelIcon fontSize="large" style={{ color: "white" }} />
                                  <span style={{ color: "white" }} >  Vous n'êtes pas éligible</span>
                                </span>
                                </div>
                                <div
                                    style={{
                                      marginTop: "24px",
                                      backgroundColor: "#E8E8E8",
                                      border: "none",
                                      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)",
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "10px",
                                      borderRadius: "0.375rem",
                                      width: '70%'
                                    }}
                                >
                                  <CRow>
                                    <CCol sm={2}>
                                      <div
                                          style={{
                                            marginTop: '50%',
                                            marginLeft: '2px',
                                            marginRight: '5px'
                                          }}
                                      >
                                        <GpsFixedOutlinedIcon
                                            sx={{
                                              fontSize: "50px",
                                              backgroundColor: "",
                                              color: "#00377D",
                                              width: 50,
                                              height: 50,
                                              borderRadius: "50%",
                                            }}
                                        />
                                      </div>
                                    </CCol>
                                    <CCol sm={5} style={{ marginTop: '5px' }}>
                                      <CCard>
                                        <CCardBody>
                                          <CCardTitle><span style={{color: '#5F99D2' }}>Latitude</span></CCardTitle>
                                          <CCardText><span style={{color: '#00377D'}}>{lat.toFixed(7)}</span></CCardText>
                                        </CCardBody>
                                      </CCard>
                                    </CCol>
                                    <CCol sm={5} className="p-1">
                                      <CCard>
                                        <CCardBody>
                                          <CCardTitle><span style={{color: '#5F99D2', }}>Longitude</span>{ " " }</CCardTitle>
                                          <CCardText><span style={{color: '#00377D'}}>{lon.toFixed(7)}</span></CCardText>
                                        </CCardBody>
                                      </CCard>
                                    </CCol>
                                  </CRow>
                                </div>
                                <br />
                                <CForm
                                    className="d-flex"
                                    style={{ textAlign: "center" }}
                                >
                                  <Button
                                      style={{ backgroundColor: "#00377D" }}
                                      onClick={() => setModalVisible(true)}
                                      variant="contained"
                                      startIcon={<AddCircleIcon style={{ color: "white", width:25, height: 25 }}/>}
                                  >
                                    <span style={{ color: "white", fontSize: 20  }}>Faire une demande</span>
                                  </Button>
                                </CForm>
                              </div>
                          )}
                        </div>
                      )
                    ) : (
                      ""
                    )}

                    <div style={{ marginTop: "25px" }}>
                      <span style={{ color: "white" }}>.</span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <CRow>
                      <CCol xs={12} className="p-1">
                        <CButton
                          //color="success"
                            style={{ backgroundColor: '#00377D' }}
                          type="submit"
                          onClick={() => setShowForm(!showForm)}
                        >
                          Formulaire
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                )}
              </div>

              {/*  */}
              <div className="componentMapGroup">
                <Button
                  endIcon={<MapIcon />}
                  sx={{ marginLeft: satelliteMapChecked ? "-15px" : "0px", marginTop: "15px", marginRight: "15px" }}
                  variant="contained"
                  size="medium"
                  style={{ color: satelliteMapChecked? "#FFD100" : "white" }}
                  onClick={toggleSatelliteMap}
                >
                  {satelliteMapChecked ? "PLAN" : "SATELLITE"}
                </Button>
                <Formik
                  onSubmit={() => {
                    setFromFormAcessibility(1);
                  }}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div className="button-container">
                        <IconButton
                          sx={{
                            color: !satelliteMapChecked ? "blue" : "white",
                          }}
                          color="primary"
                          onClick={() => {
                            setFromFormAcessibility(3);
                          }}
                        >
                          <AddCircleIcon sx={{ fontSize: "2rem", color: "#00377D"  }} />
                        </IconButton>
                        <IconButton
                          sx={{
                            color: !satelliteMapChecked ? "orangered" : "white",
                          }}
                          color="primary"
                          onClick={() => {
                            setFromFormAcessibility(4);
                          }}
                        >
                          <RemoveCircleIcon sx={{ fontSize: "2rem", color: "#FFA602" }} />
                        </IconButton>
                        <IconButton
                          sx={{
                            color: !satelliteMapChecked ? "gray" : "white",
                          }}
                          color="primary"
                          onClick={() => {
                            setFromFormAcessibility(5);
                          }}
                        >
                          <MyLocationIcon sx={{ fontSize: "2rem" }} />
                        </IconButton>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  ) : (
    <React.Fragment>
      <Spinner />
    </React.Fragment>
  );
};

export default Home;
