import {
  CCol,
  CFormFloating,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/bootstrap-react";
import React from "react";
import SendEmailService from "../apis/send_email";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import "react-toastify/dist/ReactToastify.css";

function DemandeModal({
  lat,
  lon,
  status,
  modalVisible,
  onSuccess,
  onError,
  onErrorBack,
  setModalVisible,
}) {
  return (
    <CModal
      alignment="center"
      visible={modalVisible}
      onClose={() => setModalVisible(false)}
      // size="lg"
      style={{ maxWidth: "1500px" }}
    >
      <CModalHeader style={{ backgroundColor: "#00377D" }}>
        <CModalTitle style={{ fontWeight: "600", color: "#FFFFFF" }}>
          Formulaire de demande
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <h5 style={{ color: "#5F99D2" }}>Information sur votre eligibilité</h5>
        <p>
          <CRow>
            <CCol xs={6} className="p-3 ">
              <span style={{ fontSize: 25, color: "#00377D"}}>Etat :</span>
            </CCol>
            <CCol xs={6} className="p-3 ">
              {status === "Eligible" ? (
                <span
                  style={{ fontSize: 25, fontWeight: "bold", color: "#5F99D2" }}
                >
                  {status}
                </span>
              ) : (
                <span
                  style={{ fontSize: 25, fontWeight: "bold", color: "#FFA602" }}
                >
                  {status}
                </span>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={6} className="p-3">
              <span style={{ fontSize: 20, color: "#5F99D2" }}>Latitude : </span>
              <span style={{ fontSize: 20, color: "#00377D"  }}>{lat.toFixed(7)}</span>
            </CCol>
            <CCol xs={6} className="p-3 ">
              <span style={{ fontSize: 20, color: "#5F99D2" }}>Longitude : </span>
              <span style={{ fontSize: 20, color: "#00377D" }}>{lon.toFixed(7)}</span>
            </CCol>
          </CRow>
        </p>
        <hr />
        <h5 style={{ color: "#00377D"}}>Vos renseignements</h5>

        <Formik
          initialValues={{ lastname: "", address: "", phone: "", email: "" }}
          validate={(values) => {
            let errors = {};
            // Vérifier si le nom de famille est vide
            if (!values.lastname) {
              errors.lastname = "Le champ nom et prénoms est obligatoire";
            }
            // Vérifier si le prénom est vide
            if (!values.address) {
              errors.address = "Le champ addresse est obligatoire";
            }
            // Vérifier si le numéro de téléphone est vide
            if (!values.phone) {
              errors.phone = "Le champ téléphone est obligatoire";
            } else if (values.phone.length < 8) {
              errors.phone =
                "Le numéro de téléphone doit contenir au moins 8 chiffres";
            }
            // Vérifier si l'e-mail est vide
            if (!values.email) {
              errors.email = "Le champ email est obligatoire";
            } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
              errors.email = "Veuillez saisir une adresse e-mail valide";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const phoneS = values.phone.match(/\d+/g).join("");
            SendEmailService.sendmail(
              values.lastname,
              values.address,
              phoneS,
              values.email,
              status,
              lat,
              lon
            )
              .then((response) => {
                if (response.code === 200 && response.message === "Code success" && response.description === "Email sent successfully") {
                  setModalVisible(false);
                  onSuccess();
                }else if(response.status.code === 500 && response.status.message === "Internal Server Error"){
                    onError();
                }else{
                  setModalVisible(false);
                }
              })
              .catch((error) => {
                setModalVisible(false);
                console.error("Failed to send email:", error);
                onErrorBack()
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <CRow>
                <CCol className="p-3">
                  <CInputGroup className="flex-nowrap">
                    <CFormFloating className="mb-3">
                      <TextField
                        fullWidth
                        label="NOM ET PRENOMS"
                        variant="filled"
                        type="text"
                        name="lastname"
                        id="lastname"
                        placeholder=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastname}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle style={{ color: "#00377D" }}/>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          input: { color: "#00377D" },
                          label: { color: "#5F99D2" }
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {errors.lastname && touched.lastname && errors.lastname}
                      </span>
                    </CFormFloating>
                  </CInputGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol className="p-3 ">
                  <CInputGroup className="flex-nowrap">
                    <CFormFloating className="mb-3">
                      <TextField
                        fullWidth
                        label="EMAIL"
                        variant="filled"
                        type="email"
                        name="email"
                        id="email"
                        placeholder=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon style={{ color: "#00377D" }}/>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          input: { color: "#00377D" },
                          label: { color: "#5F99D2" }
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {errors.email && touched.email && errors.email}
                      </span>
                    </CFormFloating>
                  </CInputGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol className="p-3">
                  <CInputGroup className="flex-nowrap">
                    <CFormFloating className="mb-3">
                      <TextField
                        label="TELEPHONE"
                        variant="filled"
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon style={{ color: "#00377D" }}/>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          input: { color: "#00377D" },
                          label: { color: "#5F99D2" }
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {errors.phone && touched.phone && errors.phone}
                      </span>
                    </CFormFloating>
                  </CInputGroup>
                </CCol>
                <CCol xs={6} className="p-3 ">
                  <CInputGroup className="flex-nowrap">
                    <CFormFloating className="mb-3">
                      <TextField
                        label="QUARTIER"
                        variant="filled"
                        type="text"
                        name="address"
                        id="address"
                        placeholder=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HomeIcon style={{ color: "#00377D" }}  />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          input: { color: "#00377D" },
                          label: { color: "#5F99D2" },
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {errors.address && touched.address && errors.address}
                      </span>
                    </CFormFloating>
                  </CInputGroup>
                </CCol>
              </CRow>

              <CRow>
                <CModalFooter>
                  <Button
                    color="error"
                    style={{ color: "#FFA620", borderColor: "#FFA620" }}
                    onClick={() => {
                      resetForm();
                      setModalVisible(false);
                    }}
                    variant="outlined"
                    endIcon={<ClearIcon />}
                  >
                    Annuler
                  </Button>
                  <span></span>
                  <Button
                    style={{ backgroundColor: "#00377D" }}
                    type="submit"
                    variant="contained"
                    disabled={Object.keys(errors).length !== 0}
                    endIcon={<SendIcon />}
                  >
                    Envoyer
                  </Button>
                </CModalFooter>
              </CRow>
            </form>
          )}
        </Formik>
      </CModalBody>
    </CModal>
  );
}

export default DemandeModal;
