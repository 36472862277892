import {MapContainer, TileLayer, LayersControl, Marker, Popup, Circle} from 'react-leaflet';
import React from "react";
import "../assets/css/app.css";

function Maps({position, planMapChecked, satelliteMapChecked, lat, lon, resultIsLoading, setResultIsLoading, isElligible, setIsElligible, fillLoadingOptionsOptions, fillSuccessOptionsOptions, fillFailureOptionsOptions, DefaultIcon, LocationMarker, fromForm, fromFormAcessibility}){

    const center = [lat, lon]

    return (
      <MapContainer
        className="componentA"
        center={center}
        zoom={12}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        zoomAnimation={true}
        zoomSnap={0.1}
      >
        <LayersControl>
          <LayersControl.BaseLayer checked={planMapChecked} name="Google Map">
            <TileLayer
              attribution='&copy; <a href="https://togocom.tg/">TOGOCOM</a>'
              url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer
            checked={satelliteMapChecked}
            name="Google Map Satellite"
          >
            <TileLayer
              attribution='&copy; <a href="https://togocom.tg/">TOGOCOM</a>'
              url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
            />
            <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
          </LayersControl.BaseLayer>
        </LayersControl>

        {(lat !== 6.129935 && lon !== 1.216016) && (
          <div>
            <Circle
              center={center}
              pathOptions={
                resultIsLoading
                  ? fillLoadingOptionsOptions
                  : !resultIsLoading && isElligible === true
                  ? fillSuccessOptionsOptions
                  : fillFailureOptionsOptions
              }
              radius={70}
            />
            <Marker position={center} icon={DefaultIcon}>
              <Popup>Position</Popup>
            </Marker>
          </div>
        )}

        {/* (lat !== 6.129935) ? 
          (<Circle
            center={center}
            pathOptions={
              resultIsLoading
                ? fillLoadingOptionsOptions
                : !resultIsLoading && isElligible === true
                ? fillSuccessOptionsOptions
                : fillFailureOptionsOptions
            }
            radius={70}
          />
          <Marker position={center} icon={DefaultIcon}>
            <Popup>Position</Popup>
          </Marker>) */}

        <LocationMarker
          fromForm={fromForm}
          fromFormAcessibility={fromFormAcessibility}
        />
      </MapContainer>
    );
}



export default Maps
